export enum DefaultColors {
  COMPANY = '#D61920',
  PRIMARY = '#F57040',
  SECONDARY = '#747A7A',
  ACCENT = '#FFFFFF',
  DARK_TEXT = '#484848',
  LIGHT_TEXT = '#AAAAAA',
  DARK = '#333333',
  LIGHT = '#F5F5F5',
  SUCCESS = '#2E7D32',
  WARNING = '#FFC107',
  ERROR = '#D32F2F',
}

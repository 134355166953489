import React, { Suspense, useMemo } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet, ScrollRestoration, useMatches } from '@tanstack/react-router';
import { Helmet } from 'react-helmet';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { BackgroundData } from 'typescript/interfaces/BackgroundData';
import { NonCircularLinkProps } from 'typescript/types/NonCircularLinkProps';

import { Background } from 'components/Background';
import { Toaster } from 'components/Toaster';

import { AnalyticsContextProvider } from 'hooks/useAnalytics';
import { ColorsContextProvider } from 'hooks/useColors';

import 'locales/index';
import './__root.scss';

const Root = () => {
  const matches = useMatches();

  const [rootMatch] = useMemo(() => {
    return [...matches].reverse();
  }, [matches]);

  const { title } = useMemo(() => {
    return (
      rootMatch.meta?.find((meta) => {
        return meta.title;
      }) || { title: 'TheSOURCE' }
    );
  }, [rootMatch.meta]);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title}</title>
      </Helmet>

      <ColorsContextProvider>
        <AnalyticsContextProvider>
          <Background />

          <Suspense
            fallback={
              <Box
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <CircularProgress variant="indeterminate" color="primary" size={100} />
                <Typography variant="h5" sx={{ marginY: 2 }} color="primary">
                  {'Loading ... '}
                </Typography>
              </Box>
            }
          >
            <Outlet />

            <ScrollRestoration />
            <Toaster />
          </Suspense>

          {process.env.NODE_ENV === 'development' && <TanStackRouterDevtools position="bottom-right" />}
        </AnalyticsContextProvider>
      </ColorsContextProvider>
    </React.Fragment>
  );
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  back?: NonCircularLinkProps;
  breadcrumbs: boolean;
  disableButtonBar: boolean;
  hideButtonBarButtons: string[];
  disableSupportButton: boolean;
  background?: BackgroundData;
}>()({
  component: Root,
  staticData: {
    title: 'Home',
  },
});

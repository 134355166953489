import { QueryClient, useQuery } from '@tanstack/react-query';

import { getData } from 'api/requests/GET_APP_INFO';

import { getQueryKey } from 'helpers/getQueryKey';

const key = (id: string | number) => {
  return getQueryKey('APP', id.toString());
};

export const useApp = (id: string | number) => {
  return useQuery({
    queryKey: key(id),
    queryFn: () => {
      return getData(id);
    },
  });
};

type Params = { id: string };

export const getAppDataOptions = (params: Params) => {
  return {
    queryKey: key(params.id),
    queryFn: () => {
      return getData(params.id);
    },
  };
};

export const getApp = (id: string, client: QueryClient) => {
  return client.ensureQueryData(getAppDataOptions({ id }));
};

import React, { useEffect } from 'react';

import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';
import { create } from 'zustand';

import { AppInfo } from 'api/types/AppInfo';

import { contrast, hsl } from 'helpers/generateColorVariations';

import { getAppDataOptions } from 'hooks/data/useAppData';

import { queryClient } from '../../queryClient';
import { createStyleTag } from './createStyleTag';

type ColorName = `${keyof AppInfo['colors']}Color${'Light' | 'Dark' | 'Contrast' | ''}`;
type GeneratedColors = Record<ColorName, string>;

const generateColors = (colors: AppInfo['colors']): GeneratedColors => {
  const appColors = {
    accentColor: colors.accent,
    companyColor: colors.company,
    darkColor: colors.dark,
    darkTextColor: colors.darkText,
    diplomaColor: colors.diploma,
    diplomaCheckmarkColor: colors.diplomaCheckmark,
    errorColor: colors.error,
    lightColor: colors.light,
    lightTextColor: colors.lightText,
    primaryColor: colors.primary,
    secondaryColor: colors.secondary,
    successColor: colors.success,
    warningColor: colors.warning,
  } as Record<string, string>;

  Object.keys(appColors).forEach((k) => {
    const lightColor = hsl(appColors[k], [0, 0, 10]);
    const darkColor = hsl(appColors[k], [0, 0, -10]);
    const contrastColor = contrast(appColors[k]);

    appColors[`${k}Light`] = lightColor;
    appColors[`${k}Dark`] = darkColor;
    appColors[`${k}Contrast`] = contrastColor;

    if (k === 'primaryColor') {
      appColors.primaryColorContrast = '#FFF';
    }
  });

  const output = Object.keys(appColors)
    .sort()
    .reduce((acc, key) => {
      return { ...acc, [key]: appColors[key] };
    }, {} as GeneratedColors);

  return output;
};

const generateTheme = (colors: GeneratedColors) => {
  const { palette } = createTheme();

  const newTheme: ThemeOptions = {
    // typography: {

    // },
    palette: {
      primary: palette.augmentColor({
        // TODO: This contrastText is a placeholder specifically to 760, there needs to be a way to override these on the API level
        color: { main: colors.primaryColor, contrastText: 'white' },
        name: 'primary',
      }),
      secondary: palette.augmentColor({
        color: { main: colors.secondaryColor },
        name: 'secondary',
      }),
      error: palette.augmentColor({
        color: { main: colors.errorColor },
        name: 'error',
      }),
      success: palette.augmentColor({
        color: { main: colors.successColor },
        name: 'success',
      }),
      warning: palette.augmentColor({
        color: { main: colors.warningColor },
        name: 'warning',
      }),
      accent: palette.augmentColor({
        color: { main: colors.accentColor },
        name: 'accent',
      }),
      light: palette.augmentColor({
        color: { main: colors.lightColor },
        name: 'light',
      }),
      dark: palette.augmentColor({
        color: { main: colors.darkColor },
        name: 'dark',
      }),
      white: palette.augmentColor({
        color: { main: '#FFF' },
        name: 'white',
      }),
      debug: {
        main: 'rgb(255, 0, 0)',
        light: 'rgb(0, 255, 0)',
        dark: 'rgb(0, 0, 255)',
        contrastText: 'rgb(255, 0, 255)',
      },
    },
  };

  return createTheme(newTheme);
};

type ColorsContextProviderProps = {
  children: React.ReactNode;
};

interface ColorStore {
  theme: ThemeOptions;
  setTheme: (theme: ThemeOptions) => void;

  id: number | undefined;
  /** Checks if the app is set to the default value of `760` */
  isDefault: () => boolean;

  setApp: (id: number | 'default') => Promise<void>;
}

export const useColorStore = create<ColorStore>((set, get) => {
  return {
    theme: {},
    setTheme: (theme: ThemeOptions) => {
      return set({ theme });
    },

    id: undefined,
    isDefault: () => {
      return get().id === 760;
    },

    setApp: async (rawId: number | 'default') => {
      const id = rawId === 'default' ? 760 : rawId;

      if (get().id === id) {
        return;
      }

      const app = await queryClient.ensureQueryData(getAppDataOptions({ id: id.toString() }));

      if (app === undefined) {
        throw new Error('App data is undefined'); // TODO: Replace with new Exception
      }

      if (app?.colors) {
        const variables = generateColors(app.colors);

        const newTheme = generateTheme(variables);
        set({ theme: newTheme, id });
        createStyleTag({ declaration: { variables } });
      }
    },
  };
});

export const ColorsContextProvider = ({
  children,
}: ColorsContextProviderProps): React.ReactElement<ThemeProviderProps> => {
  const { theme, setApp } = useColorStore((state) => {
    return {
      theme: state.theme,
      setApp: state.setApp,
    };
  });

  useEffect(() => {
    if (Object.keys(theme).length === 0) {
      setApp(760);
    }
  }, [theme, setApp]);

  return <ThemeProvider theme={theme}>{(Object.keys(theme).length > 0 && children) || null}</ThemeProvider>;
};

import convert from 'color-convert';

export const hsl = (hex: string, [h, s, l]: [number, number, number]) => {
  const [hue, sat, light] = convert.hex.hsl(hex);

  return `#${convert.hsl.hex([hue + h, sat + s, Math.min(100, Math.max(0, light + l))])}`;
};

export const contrast = (hex: string) => {
  const rgb = convert.hex.rgb(hex);
  const [red, green, blue] = rgb;

  /** [Trust.](https://en.wikipedia.org/wiki/Relative_luminance) */
  if ((0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255 >= 0.5) {
    return '#000000';
  }

  return '#FFFFFF';
};

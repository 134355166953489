import hash from 'hash.js';
import { kebabCase } from 'lodash-es';

interface CSSWithVariables extends Partial<CSSStyleDeclaration> {
  variables?: Record<string, string>;
}

const validateStyleTag = (styleTag: Element | null): styleTag is HTMLStyleElement => {
  return styleTag?.tagName === 'STYLE';
};

const getStyleTag = (className: string) => {
  const selector = `.${className.replace(/^\./, '')}`;
  const existingStyleTag = document.querySelector(selector);

  if (validateStyleTag(existingStyleTag)) {
    return existingStyleTag;
  }

  console.warn(`Element with className ${selector} not found, creating new style tag`);

  const styleTag = document.createElement('style');

  styleTag.setAttribute('type', 'text/css');
  styleTag.classList.add(className);

  return styleTag as HTMLStyleElement;
};

const styleDeclarationToCss = (declaration: CSSWithVariables): string => {
  const { variables, ...restProperties } = declaration;

  const variableData = Object.entries(variables || {}).map(([key, value]) => {
    return `--${kebabCase(key)}: ${value};`;
  });

  const propertyData = Object.entries(restProperties).map(([key, value]) => {
    return `${kebabCase(key)}: ${value};`;
  });

  return [variableData, propertyData]
    .flat()
    .filter((value) => {
      return value && value.length > 0;
    })
    .join('\n');
};

export const createStyleTag = ({
  className = 'travpro_css_variables',
  declaration,
  selector = ':root',
}: {
  className?: string;
  declaration: CSSWithVariables;
  selector?: string;
}) => {
  const css = styleDeclarationToCss(declaration);
  const cssHash = hash.sha256().update(css).digest('hex').slice(0, 10);

  const existingStyleTag = document.querySelector(`.${className}`);
  const existingCssHash = existingStyleTag?.getAttribute('data-hash');

  const styleTag = getStyleTag(className);
  styleTag.setAttribute('data-hash', cssHash);
  styleTag.innerHTML = `${selector} {\n${css}\n}`;

  if (existingStyleTag && cssHash !== existingCssHash) {
    existingStyleTag.replaceWith(styleTag);
  } else {
    document.head.prepend(styleTag);
  }
};
